<template>
  <div class="group w-6 h-6 relative inline-flex z-10">
    <span
      class="border border-black text-black w-6 h-6 rounded-full text-center"
      @mouseover="toggleHover"
      @mouseleave="toggleHover"
      >?</span
    >
    <div
      v-if="hover"
      :class="`bg-white text-sm text-gray-900 drop-shadow-lg rounded-md absolute ${width} translate-y-100 px-4 py-2 z-50 ${position === 'left' ? posLeft : posRight}`"
      style="white-space: pre-line">
      <span v-html="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  text: { default: "" },
  position: { default: "right"},
  width: { default: "w-64" }
});

const hover = ref(false);
const posLeft = "left-0 -translate-x-full";
const posRight = "right-0 translate-x-full";

function toggleHover() {
  hover.value = !hover.value;
}
</script>
