<template>
  <div class="flex justify-center h-12 w-full mb-8">
    <div @click="goBack">
      <svg v-if="back" class="w-7 h-7" viewbox="0 0 24 24">
        <path fill="#000000" :d="mdiArrowLeft" />
      </svg>
    </div>
    <anybill-divider v-if="back" />
    <img
    v-if="!(loading ?? false)"
      :src="logoSrc ? logoSrc : '/anybill_logo.svg'"
      class="object-contain max-w-[180px] h-16"
      :class="back ? 'mr-6' : ''"
    />
    <anybill-divider v-if="back" />
  </div>
</template>

<script setup lang="ts">
import { mdiArrowLeft } from "@mdi/js";
import AnybillDivider from "~/components/anybill-divider.vue";

const props = defineProps({
  back: Boolean,
  logoSrc: String,
  loading: Boolean
});

function goBack() {
  useRouter().back();
}
</script>

<style scoped></style>
