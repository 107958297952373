<template>
  <div v-if="!hide" class="lg:relative bottom-0 my-8 lg:mt-12 lg:mb-0 h-[70px]">
    <div v-if="!isGewinnblick" class="flex justify-end items-end">
      <span class="text-[11px]">{{ $t("global.footerCopyright") }}</span>
      <img src="/anybill_logo.svg" class="ml-2 w-20 object-contain" />
    </div>
    <div v-else class="flex flex-col items-end">
      <span class="text-[11px]">{{ $t("global.footerCopyright") }}</span>
      <img src="/anybill-Gewinnblick.svg" class="w-52 object-contain" />
    </div>
    <div class="border-b border-black w-full h-1 my-2" />
    <div class="flex flex-col mt-2 text-[11px]">
      <span>©{{ currentYear }} techreach GmbH</span>
      <div class="flex gap-2">
        <a :href="privacyLink" target="_blank">
          <div
            class="text-right underline sm:no-underline hover:underline"
          >
            {{ $t("global.footerDSE") }}
          </div>
        </a>
        <a :href="imprintLink" target="_blank">
          <div
            class="text-right underline sm:no-underline hover:underline"
          >
            {{ $t("global.footerImprint") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();

const props = defineProps({
  distriId: String,
  customerId: String,
});

const DNCustomer = [
  "cd5f6140-4cf8-499f-8ba7-08da284ae221", // Food Retail ohne CF (DN)
  "88d4610e-9ae3-455d-fa97-08da27742733", // Food Retail mit CF (DN)
  "6a4bcc33-cf34-4d91-8ba5-08da284ae221", // Fashion ohne CF (DN)
  "bf5b2c6a-64ed-4893-b4fe-08da332bf635", // Fashion mit CF (DN)
  "1132708a-4d07-4abf-8ba6-08da284ae221", // Fuel & Convenience ohne CF (DN)
  "f2822521-6b0d-47ac-fa96-08da27742733", // Fuel & Convenience mit CF (DN)
  "7b1c8725-78fd-4b06-2a28-08da34da7614", // Demo (DN)
];

const isGewinnblick = computed(
  () =>
    props.distriId === "b9b3f44c-4c10-4ce6-0d37-08d9eb02104c" ||
    props.distriId === "855037c1-e84b-41b3-c694-08d9e705efaa"
);
const hide = computed(() => DNCustomer.includes(props.customerId ?? ""));
const privacyLink = computed(() =>
  locale.value !== "de"
    ? "https://anybill.de/en/privacyBill"
    : "https://anybill.de/privacyBill"
);
const imprintLink = computed(() =>
  locale.value !== "de"
    ? "https://anybill.de/en/imprint"
    : "https://anybill.de/imprint"
);
const currentYear = computed(() => new Date().getFullYear());
</script>

<style scoped></style>
