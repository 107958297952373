import { default as indexKrz8aDLwDoMeta } from "/src/pages/[...id]/enrollment/index.vue?macro=true";
import { default as indexugG41fYiaoMeta } from "/src/pages/[...id]/enrollment/raffleTerms/index.vue?macro=true";
import { default as indextpr9vyh4laMeta } from "/src/pages/[...id]/enrollment/raffleTermsEn/index.vue?macro=true";
import { default as indexLjCa9TYT80Meta } from "/src/pages/[...id]/securityToken/index.vue?macro=true";
import { default as _91id_93q961D01iaEMeta } from "/src/pages/[id].vue?macro=true";
import { default as indexpWbLKI9lmkMeta } from "/src/pages/app/cardmanagement/index.vue?macro=true";
import { default as indexw87VE5y2sVMeta } from "/src/pages/app/index.vue?macro=true";
import { default as index3d232f3Hx1Meta } from "/src/pages/enrollment/confirmation/index.vue?macro=true";
import { default as indexMTtrn7gDubMeta } from "/src/pages/enrollment/failure/index.vue?macro=true";
import { default as indexu7gclxVNzdMeta } from "/src/pages/enrollment/success/index.vue?macro=true";
import { default as indexHW787ta9qGMeta } from "/src/pages/enrollment/unlink/index.vue?macro=true";
import { default as indexRupEYGeNhcMeta } from "/src/pages/enrollment/unlink/success/index.vue?macro=true";
import { default as indexmyNYTrgKTUMeta } from "/src/pages/enrollment/unlinked/index.vue?macro=true";
import { default as _91id_93linEyBN6zzMeta } from "/src/pages/findmy/[id].vue?macro=true";
import { default as indexiA7DkEkj2gMeta } from "/src/pages/findmy/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91id_93PpWArxvbbRMeta } from "/src/pages/qrCode/[id].vue?macro=true";
export default [
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___en",
    path: indexKrz8aDLwDoMeta?.path ?? "/en/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___de___default",
    path: indexKrz8aDLwDoMeta?.path ?? "/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___de",
    path: indexKrz8aDLwDoMeta?.path ?? "/de/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___fr",
    path: indexKrz8aDLwDoMeta?.path ?? "/fr/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___it",
    path: indexKrz8aDLwDoMeta?.path ?? "/it/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___nl",
    path: indexKrz8aDLwDoMeta?.path ?? "/nl/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrz8aDLwDoMeta?.name ?? "id-enrollment___es",
    path: indexKrz8aDLwDoMeta?.path ?? "/es/:id(.*)*/enrollment",
    meta: indexKrz8aDLwDoMeta || {},
    alias: indexKrz8aDLwDoMeta?.alias || [],
    redirect: indexKrz8aDLwDoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___en",
    path: indexugG41fYiaoMeta?.path ?? "/en/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___de___default",
    path: indexugG41fYiaoMeta?.path ?? "/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___de",
    path: indexugG41fYiaoMeta?.path ?? "/de/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___fr",
    path: indexugG41fYiaoMeta?.path ?? "/fr/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___it",
    path: indexugG41fYiaoMeta?.path ?? "/it/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___nl",
    path: indexugG41fYiaoMeta?.path ?? "/nl/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indexugG41fYiaoMeta?.name ?? "id-enrollment-raffleTerms___es",
    path: indexugG41fYiaoMeta?.path ?? "/es/:id(.*)*/enrollment/raffleTerms",
    meta: indexugG41fYiaoMeta || {},
    alias: indexugG41fYiaoMeta?.alias || [],
    redirect: indexugG41fYiaoMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTerms/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___en",
    path: indextpr9vyh4laMeta?.path ?? "/en/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___de___default",
    path: indextpr9vyh4laMeta?.path ?? "/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___de",
    path: indextpr9vyh4laMeta?.path ?? "/de/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___fr",
    path: indextpr9vyh4laMeta?.path ?? "/fr/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___it",
    path: indextpr9vyh4laMeta?.path ?? "/it/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___nl",
    path: indextpr9vyh4laMeta?.path ?? "/nl/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indextpr9vyh4laMeta?.name ?? "id-enrollment-raffleTermsEn___es",
    path: indextpr9vyh4laMeta?.path ?? "/es/:id(.*)*/enrollment/raffleTermsEn",
    meta: indextpr9vyh4laMeta || {},
    alias: indextpr9vyh4laMeta?.alias || [],
    redirect: indextpr9vyh4laMeta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/enrollment/raffleTermsEn/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___en",
    path: indexLjCa9TYT80Meta?.path ?? "/en/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___de___default",
    path: indexLjCa9TYT80Meta?.path ?? "/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___de",
    path: indexLjCa9TYT80Meta?.path ?? "/de/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___fr",
    path: indexLjCa9TYT80Meta?.path ?? "/fr/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___it",
    path: indexLjCa9TYT80Meta?.path ?? "/it/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___nl",
    path: indexLjCa9TYT80Meta?.path ?? "/nl/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: indexLjCa9TYT80Meta?.name ?? "id-securityToken___es",
    path: indexLjCa9TYT80Meta?.path ?? "/es/:id(.*)*/securityToken",
    meta: indexLjCa9TYT80Meta || {},
    alias: indexLjCa9TYT80Meta?.alias || [],
    redirect: indexLjCa9TYT80Meta?.redirect || undefined,
    component: () => import("/src/pages/[...id]/securityToken/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___en",
    path: _91id_93q961D01iaEMeta?.path ?? "/en/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___de___default",
    path: _91id_93q961D01iaEMeta?.path ?? "/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___de",
    path: _91id_93q961D01iaEMeta?.path ?? "/de/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___fr",
    path: _91id_93q961D01iaEMeta?.path ?? "/fr/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___it",
    path: _91id_93q961D01iaEMeta?.path ?? "/it/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___nl",
    path: _91id_93q961D01iaEMeta?.path ?? "/nl/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q961D01iaEMeta?.name ?? "id___es",
    path: _91id_93q961D01iaEMeta?.path ?? "/es/:id()",
    meta: _91id_93q961D01iaEMeta || {},
    alias: _91id_93q961D01iaEMeta?.alias || [],
    redirect: _91id_93q961D01iaEMeta?.redirect || undefined,
    component: () => import("/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___en",
    path: indexpWbLKI9lmkMeta?.path ?? "/en/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___de___default",
    path: indexpWbLKI9lmkMeta?.path ?? "/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___de",
    path: indexpWbLKI9lmkMeta?.path ?? "/de/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___fr",
    path: indexpWbLKI9lmkMeta?.path ?? "/fr/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___it",
    path: indexpWbLKI9lmkMeta?.path ?? "/it/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___nl",
    path: indexpWbLKI9lmkMeta?.path ?? "/nl/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpWbLKI9lmkMeta?.name ?? "app-cardmanagement___es",
    path: indexpWbLKI9lmkMeta?.path ?? "/es/app/cardmanagement",
    meta: indexpWbLKI9lmkMeta || {},
    alias: indexpWbLKI9lmkMeta?.alias || [],
    redirect: indexpWbLKI9lmkMeta?.redirect || undefined,
    component: () => import("/src/pages/app/cardmanagement/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___en",
    path: indexw87VE5y2sVMeta?.path ?? "/en/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___de___default",
    path: indexw87VE5y2sVMeta?.path ?? "/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___de",
    path: indexw87VE5y2sVMeta?.path ?? "/de/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___fr",
    path: indexw87VE5y2sVMeta?.path ?? "/fr/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___it",
    path: indexw87VE5y2sVMeta?.path ?? "/it/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___nl",
    path: indexw87VE5y2sVMeta?.path ?? "/nl/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexw87VE5y2sVMeta?.name ?? "app___es",
    path: indexw87VE5y2sVMeta?.path ?? "/es/app",
    meta: indexw87VE5y2sVMeta || {},
    alias: indexw87VE5y2sVMeta?.alias || [],
    redirect: indexw87VE5y2sVMeta?.redirect || undefined,
    component: () => import("/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___en",
    path: index3d232f3Hx1Meta?.path ?? "/en/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___de___default",
    path: index3d232f3Hx1Meta?.path ?? "/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___de",
    path: index3d232f3Hx1Meta?.path ?? "/de/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___fr",
    path: index3d232f3Hx1Meta?.path ?? "/fr/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___it",
    path: index3d232f3Hx1Meta?.path ?? "/it/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___nl",
    path: index3d232f3Hx1Meta?.path ?? "/nl/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: index3d232f3Hx1Meta?.name ?? "enrollment-confirmation___es",
    path: index3d232f3Hx1Meta?.path ?? "/es/enrollment/confirmation",
    meta: index3d232f3Hx1Meta || {},
    alias: index3d232f3Hx1Meta?.alias || [],
    redirect: index3d232f3Hx1Meta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___en",
    path: indexMTtrn7gDubMeta?.path ?? "/en/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___de___default",
    path: indexMTtrn7gDubMeta?.path ?? "/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___de",
    path: indexMTtrn7gDubMeta?.path ?? "/de/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___fr",
    path: indexMTtrn7gDubMeta?.path ?? "/fr/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___it",
    path: indexMTtrn7gDubMeta?.path ?? "/it/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___nl",
    path: indexMTtrn7gDubMeta?.path ?? "/nl/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexMTtrn7gDubMeta?.name ?? "enrollment-failure___es",
    path: indexMTtrn7gDubMeta?.path ?? "/es/enrollment/failure",
    meta: indexMTtrn7gDubMeta || {},
    alias: indexMTtrn7gDubMeta?.alias || [],
    redirect: indexMTtrn7gDubMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/failure/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___en",
    path: indexu7gclxVNzdMeta?.path ?? "/en/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___de___default",
    path: indexu7gclxVNzdMeta?.path ?? "/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___de",
    path: indexu7gclxVNzdMeta?.path ?? "/de/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___fr",
    path: indexu7gclxVNzdMeta?.path ?? "/fr/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___it",
    path: indexu7gclxVNzdMeta?.path ?? "/it/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___nl",
    path: indexu7gclxVNzdMeta?.path ?? "/nl/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7gclxVNzdMeta?.name ?? "enrollment-success___es",
    path: indexu7gclxVNzdMeta?.path ?? "/es/enrollment/success",
    meta: indexu7gclxVNzdMeta || {},
    alias: indexu7gclxVNzdMeta?.alias || [],
    redirect: indexu7gclxVNzdMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___en",
    path: indexHW787ta9qGMeta?.path ?? "/en/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___de___default",
    path: indexHW787ta9qGMeta?.path ?? "/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___de",
    path: indexHW787ta9qGMeta?.path ?? "/de/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___fr",
    path: indexHW787ta9qGMeta?.path ?? "/fr/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___it",
    path: indexHW787ta9qGMeta?.path ?? "/it/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___nl",
    path: indexHW787ta9qGMeta?.path ?? "/nl/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexHW787ta9qGMeta?.name ?? "enrollment-unlink___es",
    path: indexHW787ta9qGMeta?.path ?? "/es/enrollment/unlink",
    meta: indexHW787ta9qGMeta || {},
    alias: indexHW787ta9qGMeta?.alias || [],
    redirect: indexHW787ta9qGMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___en",
    path: indexRupEYGeNhcMeta?.path ?? "/en/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___de___default",
    path: indexRupEYGeNhcMeta?.path ?? "/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___de",
    path: indexRupEYGeNhcMeta?.path ?? "/de/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___fr",
    path: indexRupEYGeNhcMeta?.path ?? "/fr/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___it",
    path: indexRupEYGeNhcMeta?.path ?? "/it/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___nl",
    path: indexRupEYGeNhcMeta?.path ?? "/nl/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexRupEYGeNhcMeta?.name ?? "enrollment-unlink-success___es",
    path: indexRupEYGeNhcMeta?.path ?? "/es/enrollment/unlink/success",
    meta: indexRupEYGeNhcMeta || {},
    alias: indexRupEYGeNhcMeta?.alias || [],
    redirect: indexRupEYGeNhcMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlink/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___en",
    path: indexmyNYTrgKTUMeta?.path ?? "/en/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___de___default",
    path: indexmyNYTrgKTUMeta?.path ?? "/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___de",
    path: indexmyNYTrgKTUMeta?.path ?? "/de/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___fr",
    path: indexmyNYTrgKTUMeta?.path ?? "/fr/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___it",
    path: indexmyNYTrgKTUMeta?.path ?? "/it/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___nl",
    path: indexmyNYTrgKTUMeta?.path ?? "/nl/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyNYTrgKTUMeta?.name ?? "enrollment-unlinked___es",
    path: indexmyNYTrgKTUMeta?.path ?? "/es/enrollment/unlinked",
    meta: indexmyNYTrgKTUMeta || {},
    alias: indexmyNYTrgKTUMeta?.alias || [],
    redirect: indexmyNYTrgKTUMeta?.redirect || undefined,
    component: () => import("/src/pages/enrollment/unlinked/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___en",
    path: _91id_93linEyBN6zzMeta?.path ?? "/en/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___de___default",
    path: _91id_93linEyBN6zzMeta?.path ?? "/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___de",
    path: _91id_93linEyBN6zzMeta?.path ?? "/de/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___fr",
    path: _91id_93linEyBN6zzMeta?.path ?? "/fr/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___it",
    path: _91id_93linEyBN6zzMeta?.path ?? "/it/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___nl",
    path: _91id_93linEyBN6zzMeta?.path ?? "/nl/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93linEyBN6zzMeta?.name ?? "findmy-id___es",
    path: _91id_93linEyBN6zzMeta?.path ?? "/es/findmy/:id()",
    meta: _91id_93linEyBN6zzMeta || {},
    alias: _91id_93linEyBN6zzMeta?.alias || [],
    redirect: _91id_93linEyBN6zzMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___en",
    path: indexiA7DkEkj2gMeta?.path ?? "/en/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___de___default",
    path: indexiA7DkEkj2gMeta?.path ?? "/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___de",
    path: indexiA7DkEkj2gMeta?.path ?? "/de/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___fr",
    path: indexiA7DkEkj2gMeta?.path ?? "/fr/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___it",
    path: indexiA7DkEkj2gMeta?.path ?? "/it/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___nl",
    path: indexiA7DkEkj2gMeta?.path ?? "/nl/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiA7DkEkj2gMeta?.name ?? "findmy___es",
    path: indexiA7DkEkj2gMeta?.path ?? "/es/findmy",
    meta: indexiA7DkEkj2gMeta || {},
    alias: indexiA7DkEkj2gMeta?.alias || [],
    redirect: indexiA7DkEkj2gMeta?.redirect || undefined,
    component: () => import("/src/pages/findmy/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/en",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___de___default",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___de",
    path: indexvsa0KwJWqiMeta?.path ?? "/de",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___fr",
    path: indexvsa0KwJWqiMeta?.path ?? "/fr",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___it",
    path: indexvsa0KwJWqiMeta?.path ?? "/it",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___nl",
    path: indexvsa0KwJWqiMeta?.path ?? "/nl",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___es",
    path: indexvsa0KwJWqiMeta?.path ?? "/es",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___en",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/en/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___de___default",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___de",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/de/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___fr",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/fr/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___it",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/it/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___nl",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/nl/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpWArxvbbRMeta?.name ?? "qrCode-id___es",
    path: _91id_93PpWArxvbbRMeta?.path ?? "/es/qrCode/:id()",
    meta: _91id_93PpWArxvbbRMeta || {},
    alias: _91id_93PpWArxvbbRMeta?.alias || [],
    redirect: _91id_93PpWArxvbbRMeta?.redirect || undefined,
    component: () => import("/src/pages/qrCode/[id].vue").then(m => m.default || m)
  }
]