export const localeCodes = ["en","de","fr","it","nl","es"]

export const localeMessages = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "lang_en_json_en_json" */) }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "lang_de_json_de_json" */) }],
  "fr": [{ key: "../locales/fr.json", load: () => import("../locales/fr.json" /* webpackChunkName: "lang_fr_json_fr_json" */) }],
  "it": [{ key: "../locales/it.json", load: () => import("../locales/it.json" /* webpackChunkName: "lang_it_json_it_json" */) }],
  "nl": [{ key: "../locales/nl.json", load: () => import("../locales/nl.json" /* webpackChunkName: "lang_nl_json_nl_json" */) }],
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "lang_es_json_es_json" */) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":false,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  nuxtI18nOptions.vueI18n = Object({})
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"en","iso":"en-US","file":"en.json","hash":"7150f855","type":"static"}),Object({"code":"de","iso":"de-DE","file":"de.json","hash":"aa8c4345","type":"static"}),Object({"code":"fr","iso":"fr-FR","file":"fr.json","hash":"61540c45","type":"static"}),Object({"code":"it","iso":"it-IT","file":"it.json","hash":"2e0e0f0a","type":"static"}),Object({"code":"nl","iso":"nl-NL","file":"nl.json","hash":"f4234fb8","type":"static"}),Object({"code":"es","iso":"es-ES","file":"es.json","hash":"620dda4d","type":"static"})]
  nuxtI18nOptions.defaultLocale = "de"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_and_default"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "locales/"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","iso":"en-US","file":"en.json","hash":"7150f855","type":"static"}),Object({"code":"de","iso":"de-DE","file":"de.json","hash":"aa8c4345","type":"static"}),Object({"code":"fr","iso":"fr-FR","file":"fr.json","hash":"61540c45","type":"static"}),Object({"code":"it","iso":"it-IT","file":"it.json","hash":"2e0e0f0a","type":"static"}),Object({"code":"nl","iso":"nl-NL","file":"nl.json","hash":"f4234fb8","type":"static"}),Object({"code":"es","iso":"es-ES","file":"es.json","hash":"620dda4d","type":"static"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
