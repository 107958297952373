<template>
  <div
    class="z-0 w-full flex justify-center"
    @click="showLanguageSettings = false"
  >
    <div
      class="bg-anybill-canvas p-8 h-screen md:h-[80vh] lg:m-8 rounded-xl lg:shadow-md lg:bg-white w-[400px]"
    >
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col">
          <div class="flex justify-end mb-11 relative">
            <div
              class="flex flex-row place-items-center cursor-pointer items-center"
              @click.stop="openLanguageSettings"
            >
              <img
                src="/icons/icon-globe.svg"
                alt="globe-icon"
                class="w-[13px] mr-1"
              />
              <div class="font-semibold text-sm uppercase">
                {{ $i18n.locale }}
              </div>
            </div>
            <language-settings v-if="showLanguageSettings" />
          </div>
          <app-bar />
          <div class="h-6" />
          <p class="text-xl">{{ $t("receiptfinderPage.title") }}</p>
          <p class="pt-4">{{ $t("receiptfinderPage.globalSubtitle") }}</p>
          <div class="pt-8 mx-auto">
            <anybill-combobox
              id="store"
              v-model="merchant"
              :options="merchantItems"
              required
              width="w-[300px]"
              :label="$t('receiptfinderPage.globalMerchantLabel')"
            />
            <anybill-btn
              v-if="existingMerchantSelected"
              @click="gotoFindmy"
              :text="$t('receiptfinderPage.globalSubmit')"
              pill
              class="mt-8 w-full"
            />
          </div>
        </div>
        <anybill-branding />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ExternalCustomerDto from "~/models/ExternalCustomerDto";
import CustomerProvider from "~/provider/customerProvider";

const localePath = useLocalePath();

const customerProvider = new CustomerProvider();
const showLanguageSettings = ref(false);
const merchant = ref("");
const merchants = ref<ExternalCustomerDto[]>([]);

onMounted(async () => {
  const response = await customerProvider.getMerchants();

  if (response.success) {
    merchants.value = response.value;
  } else {
    /// ToDo: Implement error handling
  }
});

const merchantItems = computed(() =>
  merchants.value.map((m) => ({
    text: m.name,
    value: m.id,
  }))
);

const existingMerchantSelected = computed(() =>
  merchants.value.some((m) => m.id === merchant.value)
);

function openLanguageSettings() {
  showLanguageSettings.value = !showLanguageSettings.value;
}

async function gotoFindmy() {
  window.open(localePath(`/${merchant.value}`), "_self");
}
</script>
