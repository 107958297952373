import type { RouterOptions } from "@nuxt/schema";
import FindmyGlobal from "~/pages/findmy/index.vue";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterOptions>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp();
    const subdomain = useSubdomain();
    if (ssrContext?.event.context.subdomain) subdomain.value = ssrContext?.event.context.subdomain;

    if (!!subdomain.value?.includes("findmy")) {
      const userRoute = _routes.filter((i) => i.path.includes("/findmy/:id"));
      const userRouteMapped = userRoute.map((i) => ({
        ...i,
        path: i.path.replace("/findmy/:id()", "/:id()"),
      }));
      userRouteMapped.push({
        name: "findmy-global",
        path: "/",
        meta: {},
        alias: [],
        redirect: undefined,
        component: FindmyGlobal
      })
      
      return userRouteMapped;
    }
  }
}