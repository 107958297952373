<template>
  <div
    class="absolute top-7 right-0 bg-white shadow-xl rounded-xl pr-4 pl-2 py-3 z-50"
  >
    <div
      v-for="(language, i) in languages"
      :key="i"
      class="flex items-center my-2 cursor-pointer"
      @click="switchToLang(language)"
    >
      <img
        :src="language.icon"
        :alt="language.shortName"
        class="w-[40px]"
      />
      <div
        :class="`font-medium text-[17px] ${
          currentLanguage === language.shortName
            ? 'underline'
            : 'no-underline opacity-70'
        }`"
      >
        {{ language.name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { setLocale, locale } = useI18n();

const localeRoute = useLocaleRoute();

const currentLanguage = ref(locale.value);
const languages = ref([
  {
    name: "Deutsch",
    shortName: "de",
    icon: "/icons/flags/flag-german.svg",
  },
  {
    name: "English",
    shortName: "en",
    icon: "/icons/flags/flag-english.svg",
  },
  {
    name: "Français",
    shortName: "fr",
    icon: "/icons/flags/flag-french.svg",
  },
  {
    name: "Italiano",
    shortName: "it",
    icon: "/icons/flags/flag-italian.svg",
  },
  {
    name: "Nederlands",
    shortName: "nl",
    icon: "/icons/flags/flag-dutch.svg",
  },
  {
    name: "Español",
    shortName: "es",
    icon: "/icons/flags/flag-spain.svg",
  },
]);

onMounted(() => {
  const sortedArr = languages.value.reduce((acc: any, element: any) => {
    if (element.shortName === currentLanguage.value) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);

  languages.value = sortedArr;
});

function queryString() {
  const hasQueries = Object.keys(useRoute().query).length !== 0;
  if (hasQueries) {
    const q = useRoute().query;
    return (
      "?" +
      Object.keys(q)
        .map((key) => {
          const val = q[key];
          return encodeURIComponent(key) + "=" + encodeURIComponent(val?.toString() ?? "");
        })
        .join("&")
    );
  }
  return "";
}

function switchToLang(language: any) {  
  setLocale(language.shortName);
  history.pushState(
    {},
    "",
    localeRoute({path: useRoute().path, query: useRoute().query}, language.shortName)?.fullPath
  );
}
</script> 
