import { AnybillResult } from "models/AnybillResponse"
import ExternalCustomerDto from "models/ExternalCustomerDto";
import VendorInformationDto from "models/VendorInformationDto";

abstract class CustomerProviderConstants {
    protected readonly getMerchantsPath = `/api/customerService/get`;
    protected readonly getVendorInformationPath = `/api/customerSpecificService/get`;
}



export default class CustomerProvider extends CustomerProviderConstants {

    constructor() {
        super();
    }

    public async getMerchants(): Promise<AnybillResult<[ExternalCustomerDto]>> {
        return useTypedFetch<AnybillResult<[ExternalCustomerDto]>>(
            this.getMerchantsPath,
          );
    }

    public async getVendorInformation(identifier: string): Promise<AnybillResult<VendorInformationDto>> {
        return useTypedFetch<AnybillResult<VendorInformationDto>>(
            this.getVendorInformationPath,
            { identifier: identifier}
          );
    }
}
