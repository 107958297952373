<template>
  <button
    id="anybill-btn"
    class="p-2 overflow-hidden"
    :class="generalStyle"
    :style="`background-color: ${definedColor}`"
    @click="click"
  >
    <div
      class="flex place-content-center items-center space-x-2"
      :class="secondaryBtn ? 'h-16' : ''"
    >
      <template v-if="isLoading">
        <svg class="h-6 animate-spin" viewBox="0 0 180 180">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M90.0757 179.575C139.568 179.575 179.69 139.453 179.69 89.9604C179.69 40.4683 139.568 0.34668 90.0757 0.34668C40.5835 0.34668 0.461914 40.4683 0.461914 89.9604C0.461914 139.453 40.5835 179.575 90.0757 179.575ZM90.0757 156.158C126.636 156.158 156.273 126.521 156.273 89.9604C156.273 53.4004 126.636 23.7627 90.0757 23.7627C53.5156 23.7627 23.8779 53.4004 23.8779 89.9604C23.8779 126.521 53.5156 156.158 90.0757 156.158Z"
            :fill="primary || color === '#000000' ? 'white' : 'black'"
            fill-opacity="0.2"
          />isLoading
          <path
            d="M170.925 51.3071C178.591 67.3409 181.278 85.2068 178.79 102.63C177.876 109.028 171.338 112.665 165.113 110.926V110.926C158.89 109.187 155.364 102.724 155.974 96.2921C156.172 94.2082 156.273 92.0961 156.273 89.9604C156.273 53.4004 126.636 23.7627 90.0756 23.7627C70.1688 23.7627 52.3143 32.5496 40.1791 46.4564C35.9304 51.3253 28.8117 53.2067 23.2703 49.8823V49.8823C17.7252 46.5556 15.8707 39.304 19.8914 34.2395C30.8334 20.4571 45.6544 10.1285 62.5666 4.67347C83.2131 -1.986 105.574 -0.929833 125.501 7.646C145.428 16.2218 161.568 31.735 170.925 51.3071Z"
            :fill="primary || color === '#000000' ? 'white' : 'black'"
          />
        </svg>
      </template>
      <template v-else>
        <template v-if="!imgPath && icon">
          <svg class="w-6 h-6" viewbox="0 0 24 24">
            <path :fill="textColor" :d="icon" />
          </svg>
        </template>
        <template v-else-if="imgPath">
          <img
            :src="imgPath"
            class="w-6 h-6"
            :style="`padding: 1px; ${textColor}`"
          />
        </template>
        <template v-if="text">
          <div
            class="select-none text-center overflow-hidden overflow-ellipsis line-clamp-2"
            :style="textStyle"
          >
            {{ text }}
          </div>
        </template>
      </template>
    </div>
  </button>
</template>

<script setup lang="ts">
import Lumifier from "~/helpers/Lumifier";

const props = defineProps({
  icon: String,
  imgPath: String,
  primary: Boolean,
  text: String,
  callback: Function,
  href: String,
  smallText: Boolean,
  isLoading: Boolean,
  color: String,
  iconColor: String,
  secondaryBtn: Boolean,
  deactivated: Boolean,
  flat: Boolean,
  pill: Boolean,
  bordered: Boolean,
  callbackArgs: {}
});

const textStyle = computed(() => {
  let style = "";
  style += props.smallText ? "font-size: 0.75em !important;" : "";
  style += textColor.value;
  return style;
});
const textColor = computed(() => Lumifier.lumifiedTextColor(definedColor.value));
const definedColor = computed(() => {
  if (props.deactivated) {
    return "#D3D3D3";
  }
  return props.color?.includes("#")
    ? props.color ?? "#000000"
    : `#${props.color ?? "000000"}`;
});
const deactivatedStyle = computed(() => {
  return props.deactivated
    ? ""
    : `cursor-pointer ${props.flat ? "" : "hover:shadow-lg"}`;
});
const flatStyle = computed(() => props.flat ? "" : " shadow");
const roundedStyle = computed(() => props.pill ? " rounded-full" : " rounded-lg");
const borderedStyle = computed(() => props.bordered ? " border border-[#707974]" : "");
const generalStyle = computed(() => deactivatedStyle.value + flatStyle.value + roundedStyle.value + borderedStyle.value);

async function click() {
  if (props.deactivated) return;
  if (props.callback) {
    await props.callback(props.callbackArgs);
  }
  if (props.href) {
    const anchor = document.createElement("a");
    anchor.href = props.href;
    anchor.target = "_blank";
    anchor.click();
    anchor.remove();
  }
}
</script>

<style scoped></style>
