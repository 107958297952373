<template>
    <div class="flex flex-col">
        <div class="flex gap-2 items-center">
        <div
            class="relative flex border border-black p-3 rounded-md focus-within:border-anybill-primary"
        >
            <label
                class="absolute -top-2 px-2 text-xs items-center bg-anybill-canvas md:bg-white"
                :for="`${label}-select`"
                >
                <span>{{ label }}</span>
            </label>
            <input :name="`${label}-select`" :class="`${width} combobox`" v-model="inputValue" @input="openMenu = true" @click="openMenu = true" />
            <div
                v-if="openMenu"
                :id="id"
                :class="`absolute top-12 left-0 z-50 w-[245px] max-h-[40vh] overflow-auto border border-black p-4 bg-white rounded-xl combobox`"
                >
                <div
                    v-for="(option, i) in options"
                    class="bg-white cursor-pointer mb-2"
                    :key="i"
                    @click.stop="setValue(option.value)"
                >
                    <p class="mb-2">{{ option.text }}</p>
                    <div v-if="i !== options.length - 1" class="w-full border border-slate-300 "></div>
                </div>
                <div v-if="options.length === 0" class="text-slate-400">{{ $t("global.noData") }}</div>
            </div>
        </div>
        <anybill-tooltip
            v-if="tooltipText"
            :text="tooltipText"
            :position="tooltipPosition"
            />
        </div>
        <div class="flex flex-col items-center justify-between">
        <template v-if="errorBag.length > 0">
            <template v-for="(err, i) in errorBag" :key="`${label}Err${i}`">
            <span class="text-red-600 text-xs">{{ err }}</span>
            </template>
        </template>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import AnybillTooltip from "~/components/anybill-tooltip.vue";

const props = defineProps({
    id: String,
    label: String,
    options: null,
    modelValue: String,
    errors: {},
    disabled: Boolean,
    tooltipText: String,
    tooltipPosition: String,
    width: String
});

const options = ref(props.options);

const _inputValue = ref("");
const openMenu = ref(false);

const onClickOutside = (e: any) => {
    if (!e.target.className.includes("combobox")) {
        openMenu.value = false;
    }
};

onMounted(() => {
    inputValue.value = props.modelValue as string;
    window.addEventListener("click", onClickOutside);
});

onDeactivated(() => {
    if (process.client) {
     window.removeEventListener("click", onClickOutside);
    }
});

const emit = defineEmits(["update:modelValue", "update:errors"]);

const syncedValue = computed({
    get: () => props.modelValue,
    set: (val) => emit("update:modelValue", val)
});

const inputValue = computed({
    get: () => _inputValue.value,
    set: (val) => {
        try {
            _inputValue.value = props.options.find((o: any) => o.value === val).text;
        } catch {
            _inputValue.value = val;
        }
        syncedValue.value = val;
    }
});

watch(inputValue, () => {
    options.value = props.options.filter((o: any) => o.text.toLowerCase().includes(_inputValue.value.toLowerCase()))
})

watch(openMenu, () => {
    options.value = props.options
})


const syncedErrors = computed({
    get: () => props.errors,
    set: (val) => emit("update:errors", val)
});

const errorBag = computed(() => removeDuplicates(syncedErrors.value));

function clearErrors() {
    syncedErrors.value = [];
}

function removeDuplicates(duplicates: any) {
    return [...new Set(duplicates)];
}

function setValue(val: string) {
    syncedValue.value = val;
    inputValue.value = val;
    openMenu.value = false;
}
</script>

<style scoped></style>