export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"apple-web-capable","name":"apple-mobile-web-app-capable","content":"yes"},{"hid":"apple-status-bar","name":"apple-mobile-web-status-bar-style","content":"black"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/icons/favicon256.ico"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/icons/favicon128.ico"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.module.min.js","defer":true,"async":true},{"src":"https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.min.js","defer":true,"async":true},{"src":"https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js","defer":true,"async":true,"crossorigin":"anonymous"}],"noscript":[],"title":"Dein digitaler Kassenbon"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null